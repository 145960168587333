class MiError extends Error {
  constructor(message, detalles) {
    super(message)
    this.name = 'MiError'
    this.detalles = detalles
  }
}

// Exporta la clase para que esté disponible globalmente
export { MiError }
