
import TextEditor from '@/components/UI/Editor/TextEditor.vue'
export default {
  // eslint-disable-next-line vue/component-definition-name-casing
  name: 'v-custom-richtext',
  components: { TextEditor },
  // eslint-disable-next-line vue/require-prop-types
  props: ['type', 'value', 'obj'],
  data() {
    return {
      text: '',
    }
  },
  computed: {
    input: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }, // listen to @input="handler"
    },
  },
  mounted() {
    this.text = this.obj.schema.richTextContent
  },
}
