import { setDocFirestore } from '@/services/firebaseWrite'

export const getLastBrand = async ($fire: any) => {
  return await $fire.firestore.collection('brand').limit(1).get()
}

export const getBrand = async ($fire: any, id: string) => {
  return await $fire.firestore.collection('brand').doc(id).get()
}

export const setBrand = async ($fire: any, data: any) => {
  return await $fire.firestore.collection('brand').add(data)
}

export const updateBrand = async ($fire: any, id: string, body: any) => {
  // const data = { ...body }
  // return await $fire.firestore
  //   .collection('brand')
  //   .doc(id)
  //   .set(data, { merge: true })
  return await setDocFirestore($fire, `brand/${id}`, { ...body }, true)
}

export const updatePostsListBrand = async (
  $fire: any,
  module: string,
  submodule: any,
  body: any
) => {
  // const data = { ...body }
  // return await $fire.firestore
  //   .collection('modules')
  //   .doc(module)
  //   .collection(submodule)
  //   .doc('settings')
  //   .set(data, { merge: true })
  return await setDocFirestore(
    $fire,
    `modules/${module}/${submodule}/settings`,
    { ...body },
    true
  )
}
