import { find, includes } from 'lodash'

export default function ({ store, redirect, route }) {
  // Valida si usuario esta logueado y es superadmin
  if (store.state.loggedIn && !isSuperAdmin(store.state.users.user)) {
    // Si no es superadmin pero es admin valida si tiene permitido ver el módulo
    if (
      store.state.loggedIn &&
      isAdmin(store.state.users.user) &&
      !isAllowedModule(store.state.users.user, route)
    ) {
      return redirect('/forbidden')
    } else if (store.state.loggedIn && !isAdmin(store.state.users.user)) {
      return redirect('/forbidden')
    }
  }
}

function isAllowedModule(user, route) {
  let moduleId = route.params.moduleId
  if (route.query && route.query.moduleId) moduleId = route.query.moduleId
  const result = find(user.availableModules, (availableModule) => {
    return availableModule.module === moduleId
  })
  return !!result
}

function isAdmin(user) {
  return (
    user && user.role && includes(['admin', 'operator', 'editor'], user.role)
  )
}

function isSuperAdmin(user) {
  return user && user.role && user.role === 'superadmin'
}
