import { find } from 'lodash'

export default function ({ store, redirect }) {
  if (store.state.loggedIn && !isAdminInspector(store.state.users.user)) {
    return redirect('/forbidden')
    // console.log('jsjsjs')
  }
}

// Vistas abiertas solo para 'superadmin', 'admin', 'editor'
function isAdminInspector(user) {
  let isAdmin = false
  if (user.role === 'superadmin') {
    // If user is superadmin can access to view
    return true
  } else if (user.auxRole && user.auxRole === 'officer') {
    // If user isn't superadmin validate auxRole and module in availableModules
    const hasModule = find(user.availableModules, (o) => {
      return o.module === 'inspectors'
    })
    isAdmin = !hasModule ? false : hasModule.role === 'admin'
  }
  return isAdmin
}
