import { includes, find } from 'lodash'

export default function ({ store, redirect }) {
  if (
    store.state.loggedIn &&
    (isAdmin(store.state.users.user) ||
      isAdminInspector(store.state.users.user) ||
      isAdminAppt(store.state.users.user) ||
      isAdminPayments(store.state.users.user))
  ) {
    // permit
  } else {
    return redirect('/forbidden')
  }
}

// Vistas abiertas solo para 'superadmin', 'admin', 'editor'
function isAdmin(user) {
  return (
    user &&
    user.role &&
    includes(['superadmin', 'admin', 'editor', 'operator'], user.role)
  )
}
function isAdminInspector(user) {
  let isAdmin = false
  if (user.role === 'superadmin') {
    // If user is superadmin can access to view
    return true
  } else if (user.auxRole && user.auxRole === 'officer') {
    // If user isn't superadmin validate auxRole and module in availableModules
    const hasModule = find(user.availableModules, (o) => {
      return o.module === 'inspectors'
    })
    isAdmin = !hasModule
      ? false
      : ['admin', 'editor', 'operator'].includes(hasModule.role)
  }
  return isAdmin
}

// Vistas abiertas solo para 'superadmin', 'admin', 'editor'
function isAdminAppt(user) {
  let isAdmin = false
  if (user.role === 'superadmin') {
    // If user is superadmin can access to view
    return true
  } else if (user.auxRole && user.auxRole === 'officer') {
    // If user isn't superadmin validate auxRole and module in availableModules
    const hasModule = find(user.availableModules, (o) => {
      return o.module === 'appointments'
    })
    isAdmin = !hasModule
      ? false
      : ['admin', 'editor', 'operator'].includes(hasModule.role)
  }
  return isAdmin
}
// Vistas abiertas solo para 'superadmin', 'admin', 'editor'
function isAdminPayments(user) {
  let isAdmin = false
  if (user.role === 'superadmin') {
    // If user is superadmin can access to view
    return true
  } else if (user.auxRole && user.auxRole === 'officer') {
    // If user isn't superadmin validate auxRole and module in availableModules
    const hasModule = find(user.availableModules, (o) => {
      return o.module === 'payments'
    })
    isAdmin = !hasModule
      ? false
      : ['admin', 'tesorero'].includes(hasModule.role)
  }
  return isAdmin
}
