export const state = () => ({
  openEditor: false,
})

export const mutations = {
  TOGGLE_EDITOR(state) {
    state.openEditor = !state.openEditor
  },
}

export const actions = {
  toggleEditor({ commit }) {
    commit('TOGGLE_EDITOR')
  },
}

export const getters = {}
