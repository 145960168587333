export const state = () => ({
  show: false,
  loaderNotify: {
    type: 'success',
    texto: [],
    timeout: 12000,
    // show: false,
    interval: 5000,
    result: null,
    finish: null,
    query: '',
    path: '',
    redirect: false,
    titleRedirect: '',
    download: false,
    titleButton: null,
    pathButton: null,
    cancelButton: false, // Show cancel button
  },
  textLoader: {
    text: '',
    show: false,
    index: 0,
  },
  indexLastVisible: null,
  absolute: true,
  overlay: false,
  showButton: false,
  cancelProcess: false, // Indicates if the process was cancelled
  tiempoRestante: {
    hora: 0,
    minuto: 0,
    segundo: 0,
    segundos: 0,
    activo: false,
  },
  eventRedirect: false,
})

export const mutations = {
  SHOW_LOADER(state, payload) {
    state.loaderNotify.type = payload.type ? payload.type : 'info'
    state.loaderNotify.texto = payload.message
    state.show = true
    state.loaderNotify.interval = payload.interval ? payload.interval : 5000
    state.loaderNotify.result = payload.result ? payload.result : null
    state.loaderNotify.finish = payload.finish ? payload.finish : null
    state.loaderNotify.download = payload.download ? payload.download : false
    state.loaderNotify.path = ''
    state.loaderNotify.titleButton = ''
    state.loaderNotify.pathButton = null
    state.loaderNotify.cancelButton = payload.cancelButton
      ? payload.cancelButton
      : false
  },
  CLOSE_LOADER(state) {
    state.show = false
  },
  SHOW_TEXT(state, payload) {
    if (state.loaderNotify.texto[payload.index]) {
      state.loaderNotify.texto[payload.index].visible = payload.visible
        ? payload.visible
        : false
    }
  },
  ADD_TEXT(state, payload) {
    if (!payload.message.visible) {
      payload.message.visible = false
    }
    state.loaderNotify.texto.push(payload.message)
    state.loaderNotify.result = payload.result ? payload.result : null
    if (payload.finish) {
      state.loaderNotify.finish = payload.finish
    }
    state.loaderNotify.type = payload.type ? payload.type : ''
    state.loaderNotify.download = payload.download ? payload.download : false
  },
  RESULT(state, payload) {
    state.loaderNotify.result = payload.result
  },
  SHOW_TEXT_LOADER(state, payload) {
    state.textLoader = payload.textLoader
  },
  SET_TEXT_LOADER(state, payload) {
    state.textLoader = payload.textLoader
  },
  FINISH(state, payload) {
    if (payload.finish) {
      state.loaderNotify.finish = payload.finish
    }
    if (payload.show) {
      state.show = payload.show
    }
    if (payload.download) {
      state.loaderNotify.download = payload.download
    }
  },
  CLEAN_DATA(state) {
    state.loaderNotify.texto = []
    state.loaderNotify.redirect = false
    state.loaderNotify.titleRedirect = ''
    state.loaderNotify.download = false
    state.loaderNotify.titleButton = null
    state.loaderNotify.pathButton = null
  },
  REDIRECT(state, payload) {
    state.loaderNotify.redirect = payload.redirect
    state.loaderNotify.titleRedirect = payload.titleRedirect
    state.loaderNotify.path = payload.path
    state.loaderNotify.finish = null
  },
  SET_CONFIG_URL(state, payload) {
    state.loaderNotify.path = payload.path
    state.loaderNotify.query = payload.query
    state.loaderNotify.download = false
  },
  SHOW_BUTTON(state, payload) {
    state.loaderNotify.pathButton = payload.pathButton
    state.loaderNotify.titleButton = payload.titleButton
      ? payload.titleButton
      : null
  },
  SHOW_CANCEL_BUTTON(state, payload) {
    state.loaderNotify.cancelButton = payload.cancelButton
      ? payload.cancelButton
      : false
  },
  CANCEL_PROCESS(state, payload) {
    state.cancelProcess = payload.cancelProcess || false
  },
  SET_LOADER(state, payload) {
    state.loaderNotify.texto = payload.loaderNotify.texto
    state.show = payload.show

    state.loaderNotify = payload.loaderNotify
    state.indexLastVisible = payload.indexLastVisible
    state.absolute = payload.absolute
    state.overlay = payload.overlay
    state.showButton = payload.showButton
    state.tiempoRestante = payload.tiempoRestante
    state.eventRedirect = payload.eventRedirect
  },
}

export const actions = {
  open({ commit }, payload) {
    commit('SHOW_LOADER', payload)
  },
  close({ commit }) {
    commit('CLOSE_LOADER')
  },
  show_text({ commit }, payload) {
    commit('SHOW_TEXT', payload)
  },
  show_cancel_button({ commit }, payload) {
    commit('SHOW_CANCEL_BUTTON', payload)
  },
  cancel_process({ commit }, payload) {
    commit('CANCEL_PROCESS', payload)
  },
  add_text({ commit }, payload) {
    setInterval(function () {
      commit('ADD_TEXT', payload)
    }, 5000)
  },
  result({ commit }, payload) {
    commit('RESULT', payload)
  },
  finish({ commit }, payload) {
    commit('FINISH', payload)
  },
  clean_data({ commit }) {
    commit('CLEAN_DATA')
  },
  set_config_url({ commit }) {
    commit('SET_CONFIG_URL')
  },
}

export const getters = {
  loaderNotify(state) {
    return state
  },
  processCanceled(state) {
    return state.cancelProcess
  },
}
