import { setDocFirestore } from '@/services/firebaseWrite'

export const getSettings = async ($fire: any, id: string) => {
  return await $fire.firestore.collection('settings').doc(id).get()
}

export const getLastSettings = async ($fire: any) => {
  return await $fire.firestore.collection('settings').limit(1).get()
}

export const setSettings = async ($fire: any, data: any) => {
  return await $fire.firestore.collection('settings').add(data)
}

export const updateSettings = async ($fire: any, id: string, body: any) => {
  // const data = { ...body }
  // return await $fire.firestore
  //   .collection('settings')
  //   .doc(id)
  //   .set(data, { merge: true })
  return await setDocFirestore($fire, `settings/${id}`, { ...body }, true)
}
