import { cloneDeep } from 'lodash'

import { getFilters } from '@/services/Filters'

export const state = () => ({
  filters: [],
})

export const mutations = {
  SET_FILTERS(state, filters) {
    state.filters = cloneDeep(filters)
  },
  ADD_FILTER_TO_ARRAY(state, catalog) {
    state.filters.push(catalog)
  },
  UPDATE_FILTER(state, catalog) {
    state.filters = state.filters.map((element) => {
      return element.id === catalog.id ? catalog : element
    })
  },
  REMOVE_FILTER(state, catalog) {
    let index = null
    index = state.filters.findIndex((element) => {
      return element.catalogId === catalog.id
    })
    if (index !== -1) state.filters.splice(index, 1)
  },
}

export const actions = {
  async get({ commit }) {
    const filtersArray = []
    await getFilters(this.$fire).then((documents) => {
      if (!documents.empty) {
        documents.forEach((doc) => {
          const data = {
            id: doc.id,
            ...doc.data(),
          }
          filtersArray.push(data)
        })

        commit('SET_FILTERS', filtersArray)
      }
    })
  },
  addFilter({ commit }, payload) {
    commit('ADD_FILTER_TO_ARRAY', payload)
  },
  setFilters({ commit }, payload) {
    commit('SET_FILTERS', payload)
  },
  updateFilter({ commit }, payload) {
    commit('UPDATE_FILTER', payload)
  },
  removeFilter({ commit }, payload) {
    commit('REMOVE_FILTER', payload)
  },
}

export const getters = {
  getFilters(state) {
    return state.filters
  },
  getFilterById: (state) => (id) => {
    return state.filters.find((filter) => filter.id === id)
  },
}
