import * as _ from 'lodash'
import { getAllPaymentUMAS } from '@/services/Sales'

export const state = () => ({
  paymentUMA: [],
})

export const mutations = {
  SET_PAYMENTUMA(state, paymentUMA) {
    state.paymentUMA = _.cloneDeep(paymentUMA)
  },
}

export const actions = {
  async get({ commit }) {
    try {
      const result = await getAllPaymentUMAS(this.$fire)
      const paymentUMA = []
      if (!result.empty) {
        result.forEach((doc) => {
          paymentUMA.push({ id: doc.id, ...doc.data() })
        })
      }
      commit('SET_PAYMENTUMA', paymentUMA)
    } catch (error) {
      // @todo Handle Error
      // eslint-disable-next-line no-console
      console.log(error)
      throw new Error(error)
    }
  },
}

export const getters = {
  getPaymentUMAS(state) {
    return state.paymentUMA
  },
}
