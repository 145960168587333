import { setDocFirestore } from '@/services/firebaseWrite'

export const saveBatchFilters = async (
  $fire: any,
  firebase: any,
  docs: [],
  collection: string
) => {
  const batch = $fire.firestore.batch()
  docs.forEach((doc: any) => {
    doc.createdAt = !doc.createdAt
      ? firebase.firestore.Timestamp.now()
      : doc.createdAt
    doc.catalogCreatedAt = !doc.catalogCreatedAt
      ? doc.created
      : doc.catalogCreatedAt
    doc.catalogId = !doc.catalogId ? doc.id : doc.catalogId

    delete doc.created
    delete doc.createdDate
    delete doc.filterCreatedAt
    delete doc.id

    const docRef = $fire.firestore.collection(collection).doc()
    batch.set(docRef, doc)
  })
  return await batch.commit()
}

export const deleteBatchFilters = async (
  $fire: any,
  docs: [],
  collection: string
) => {
  const batch = $fire.firestore.batch()
  docs.forEach((doc: any) => {
    const docRef = $fire.firestore.collection(collection).doc(doc.id)
    batch.delete(docRef)
  })
  return await batch.commit()
}

export const getFilters = async ($fire: any) => {
  return await $fire.firestore.collection('filters').get()
}

export const saveFilter = async ($fire: any, catalog: any) => {
  const newFilter = await $fire.firestore.collection('filters').doc()

  // newFilter.set({
  //   ...catalog,
  //   id: newFilter.id,
  //   path: newFilter.path,
  // })
  await setDocFirestore(
    $fire,
    `filters/${newFilter.id}`,
    {
      ...catalog,
      id: newFilter.id,
      path: newFilter.path,
    },
    false
  )

  return { ...catalog, id: newFilter.id, path: newFilter.path }
}

export const updateFilter = async ($fire: any, id: string, body: any) => {
  // const data = { ...body }
  // return await $fire.firestore
  //   .collection('filters')
  //   .doc(id)
  //   .set(
  //     {
  //       ...data,
  //     },
  //     { merge: true }
  //   )
  return await setDocFirestore($fire, `filters/${id}`, { ...body }, true)
}

export const deleteFilter = async ($fire: any, id: string) => {
  return await $fire.firestore.collection('filters').doc(id).delete()
}

export const deleteFilterFromCatalogs = async ($fire: any, id: string) => {
  return await $fire.firestore
    .collection('filters')
    .where('catalogId', '==', id)
    .get()
    .then((querySnapshot: any) => {
      querySnapshot.forEach((doc: any) => {
        doc.ref.delete()
      })
    })
}
