
export default {
  name: 'TipTapMenuBar',
  props: {
    editor: {
      type: Object,
      required: true,
    },
    miniMenu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      toggle_multiple: [],
      items: [
        {
          icon: 'mdi-format-bold',
          title: 'Bold',
          slug: 'bold',
          action: () => this.editor.chain().focus().toggleBold().run(),
          isActive: () => this.editor.isActive('bold'),
          showInMiniMenu: true,
        },
        {
          icon: 'mdi-format-italic',
          title: 'Italic',
          slug: 'italic',
          action: () => this.editor.chain().focus().toggleItalic().run(),
          isActive: () => this.editor.isActive('italic'),
          showInMiniMenu: true,
        },
        {
          icon: 'mdi-format-strikethrough',
          title: 'Strike',
          slug: 'sgtrike',
          action: () => this.editor.chain().focus().toggleStrike().run(),
          isActive: () => this.editor.isActive('strike'),
          showInMiniMenu: true,
        },
        {
          icon: 'mdi-link',
          title: 'Link',
          slug: 'link',
          action: this.setLink,
          isActive: () => this.editor.isActive('link'),
          showInMiniMenu: true,
        },
        {
          icon: 'mdi-link-off',
          title: 'Unset link',
          slug: 'unlink',
          action: () => this.editor.chain().focus().unsetLink().run(),
          isActive: () => this.editor.isActive('link'),
          showInMiniMenu: true,
        },

        {
          icon: 'mdi-format-header-1',
          title: 'Heading 1',
          action: () =>
            this.editor.chain().focus().toggleHeading({ level: 1 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 1 }),
          showInMiniMenu: false,
        },
        {
          icon: 'mdi-format-header-2',
          title: 'Heading 2',
          action: () =>
            this.editor.chain().focus().toggleHeading({ level: 2 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 2 }),
          showInMiniMenu: false,
        },
        {
          icon: 'mdi-format-paragraph',
          title: 'Paragraph',
          action: () => this.editor.chain().focus().setParagraph().run(),
          isActive: () => this.editor.isActive('paragraph'),
          showInMiniMenu: true,
        },
        {
          icon: 'mdi-format-list-bulleted',
          title: 'Bullet List',
          action: () => this.editor.chain().focus().toggleBulletList().run(),
          isActive: () => this.editor.isActive('bulletList'),
          showInMiniMenu: true,
        },
        {
          icon: 'mdi-format-list-numbered',
          title: 'Ordered List',
          action: () => this.editor.chain().focus().toggleOrderedList().run(),
          isActive: () => this.editor.isActive('orderedList'),
          showInMiniMenu: true,
        },
        {
          icon: 'mdi-code-tags',
          title: 'Code Block',
          action: () => this.editor.chain().focus().toggleCodeBlock().run(),
          isActive: () => this.editor.isActive('codeBlock'),
          showInMiniMenu: false,
        },

        {
          icon: 'mdi-minus',
          title: 'Horizontal Rule',
          action: () => this.editor.chain().focus().setHorizontalRule().run(),
          showInMiniMenu: false,
        },

        {
          icon: 'mdi-wrap',
          title: 'Hard Break',
          action: () => this.editor.chain().focus().setHardBreak().run(),
          showInMiniMenu: true,
        },
        {
          icon: 'mdi-format-clear',
          title: 'Clear Format',
          action: () =>
            this.editor.chain().focus().clearNodes().unsetAllMarks().run(),
          showInMiniMenu: true,
        },

        {
          icon: 'mdi-arrow-u-left-top',
          title: 'Undo',
          action: () => this.editor.chain().focus().undo().run(),
          showInMiniMenu: false,
        },
        {
          icon: 'mdi-arrow-u-right-top',
          title: 'Redo',
          action: () => this.editor.chain().focus().redo().run(),
          showInMiniMenu: false,
        },
      ],
    }
  },

  methods: {
    setLink() {
      const previousUrl = this.editor.getAttributes('link').href
      const url = window.prompt('URL', previousUrl)
      if (url === null) {
        return
      }
      if (url === '') {
        this.editor.chain().focus().extendMarkRange('link').unsetLink().run()
        return
      }
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run()
    },
  },
}
