import { setDocFirestore } from '@/services/firebaseWrite'

export const getAuthConfigById = async ($fire: any, id: string) => {
  return await $fire.firestore.collection('authConfig').doc(id).get()
}

export const getAuthConfig = async ($fire: any) => {
  return await $fire.firestore.collection('authConfig').limit(1).get()
}

export const setAuthConfig = async ($fire: any, data: any) => {
  return await $fire.firestore.collection('authConfig').add(data)
}

export const updateAuthConfig = async ($fire: any, id: string, body: any) => {
  // const data = { ...body }
  // return await $fire.firestore.collection('authConfig').doc(id).set(
  //   {
  //     data,
  //   },
  //   { merge: true }
  // )
  return await setDocFirestore($fire, `authConfig/${id}`, { ...body }, true)
}
