export const state = () => ({
  show: false,
})

export const mutations = {
  SHOW_LOADER(state) {
    state.show = true
  },
  CLOSE_LOADER(state) {
    if (state.show === true) {
      state.show = false
    }
  },
}

export const actions = {
  open({ commit }, payload) {
    commit('SHOW_LOADER', payload)
  },
  close({ commit }) {
    commit('CLOSE_LOADER')
  },
}
