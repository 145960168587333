import createApp from './app.js'

import authService from './service.auth.js'
import firestoreService from './service.firestore.js'
import storageService from './service.storage.js'
import functionsService from './service.functions.js'
import performanceService from './service.performance.js'
import analyticsService from './service.analytics.js'
import appCheckService from './service.appCheck.js'

const appConfig = {"apiKey":"AIzaSyBHe4DzcVyMY1b9zZ47BXWu3qVOqnfQzZ0","authDomain":"os-gobierno-de-nuevo-leon.firebaseapp.com","databaseURL":"https:\u002F\u002Fos-gobierno-de-nuevo-leon.firebaseio.com","projectId":"os-gobierno-de-nuevo-leon","storageBucket":"os-gobierno-de-nuevo-leon.appspot.com","messagingSenderId":"617862383791","appId":"1:617862383791:web:08f0ad62baceebdd13ac5a","measurementId":"G-85QETDZ7YN"}

export default async (ctx, inject) => {
  const { firebase, session } = await createApp(appConfig, ctx)

  let servicePromises = []

  if (process.server) {
    servicePromises = [
      authService(session, firebase, ctx, inject),
    firestoreService(session, firebase, ctx, inject),
    storageService(session, firebase, ctx, inject),
    functionsService(session, firebase, ctx, inject),

    ]
  }

  if (process.client) {
    servicePromises = [
      authService(session, firebase, ctx, inject),
      firestoreService(session, firebase, ctx, inject),
      storageService(session, firebase, ctx, inject),
      functionsService(session, firebase, ctx, inject),
      performanceService(session, firebase, ctx, inject),
      analyticsService(session, firebase, ctx, inject),
      appCheckService(session, firebase, ctx, inject),

    ]
  }

  const [
    auth,
    firestore,
    storage,
    functions,
    performance,
    analytics,
    appCheck
  ] = await Promise.all(servicePromises)

  const fire = {
    auth: auth,
    firestore: firestore,
    storage: storage,
    functions: functions,
    performance: performance,
    analytics: analytics,
    appCheck: appCheck
  }

    inject('fireModule', firebase)
    ctx.$fireModule = firebase

  inject('fire', fire)
  ctx.$fire = fire
}

function forceInject (ctx, inject, key, value) {
  inject(key, value)
  const injectKey = '$' + key
  ctx[injectKey] = value
  if (typeof window !== "undefined" && window.$nuxt) {
  // If clause makes sure it's only run when ready() is called in a component, not in a plugin.
    window.$nuxt.$options[injectKey] = value
  }
}