
import { format } from 'date-fns'
import { mapGetters } from 'vuex'
import LicenseIcon from '@/components/icons/LicenseIcon.vue'
import SearchIcon from '@/components/icons/SearchIcon.vue'

export default {
  name: 'DialogFile',
  components: {
    LicenseIcon,
    SearchIcon,
  },
  props: {
    answerInfo: {
      type: Object,
      default() {
        return {}
      },
    },
    questionInfo: {
      type: Object,
      default() {
        return null
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ruleVal: [(v) => !!v || this.$t('fieldRequired')],
      openDialog: false,
      tabs: 0,
      fileToUpload: null,
      saveToDocuments: null,
      documentType: 'documents',
      searchInput: null,
      documents: [],
      documentOptions: [
        { id: 'documents', text: 'Mis documentos' },
        { id: 'documentsFinals', text: 'Documentos oficiales' },
        { id: 'certs', text: 'Certificados' },
      ],
      itemsPerPage: 6,
      state: 'init',
      disableLoad: false,
      firstVisible: null,
      lastVisible: null,
      loading: false,
      selectedDocument: null,
      documentName: null,
    }
  },
  computed: {
    fileInputAcceptedFiles() {
      let acceptedFormats = []
      if (
        this.fileData.acceptedFiles &&
        this.fileData.acceptedFiles.length > 0
      ) {
        acceptedFormats = this.fileData.acceptedFiles.map((format) => {
          if (
            [
              'dwf',
              'dxf',
              'dwg',
              'kmz',
              'kml',
              'shp',
              'shx',
              'dbf',
              'syn',
              'vsdx',
            ].includes(format)
          )
            return `.${format}`
          else return format
        })
      } else {
        acceptedFormats = ['application/pdf']
      }

      return acceptedFormats.join(', ')
    },
    rulesAcceptedFiles() {
      return [
        (value) =>
          this.fileData.acceptedFiles.includes(value?.type) ||
          this.fileData.acceptedFiles.includes(value?.name.split('.').pop()) ||
          !value ||
          this.$t('solo-se-aceptan'),
      ]
    },
    fileData() {
      return this.questionInfo
    },
    validUpload() {
      if (this.tabs === 'tab-1') {
        return (
          this.validUploadFile &&
          this.fileToUpload !== null &&
          (this.saveToDocuments === false ||
            (this.saveToDocuments === true && this.documentName))
        )
      } else {
        return this.validSelectedFile && this.selectedDocument !== null
      }
    },
    validUploadFile() {
      const maxFileSize = this.fileData.maxFileSize
        ? this.fileData.maxFileSize
        : 2
      const acceptedFiles =
        this.fileData.acceptedFiles.length > 0
          ? this.fileData.acceptedFiles
          : null

      let fileExtension = ''
      const nameArray = this.fileToUpload?.name.split('.')
      if (nameArray && nameArray.length > 0) {
        fileExtension = nameArray[nameArray.length - 1]
      }

      return (
        this.fileToUpload?.size < maxFileSize * 1e6 &&
        (acceptedFiles
          ? acceptedFiles.includes(this.fileToUpload?.type) ||
            acceptedFiles.includes(fileExtension)
          : true)
      )
    },
    validSelectedFile() {
      const maxFileSize = this.fileData.maxFileSize
        ? this.fileData.maxFileSize
        : 2

      const acceptedFiles =
        this.fileData.acceptedFiles.length > 0
          ? this.fileData.acceptedFiles
          : null

      return (
        this.selectedDocument?.size < maxFileSize * 1e6 &&
        (acceptedFiles
          ? acceptedFiles.includes(this.selectedDocument?.mime) ||
            acceptedFiles.includes(this.selectedDocument?.type)
          : true)
      )
    },
    ...mapGetters({
      user: 'users/user',
    }),
  },
  async mounted() {
    await this.get()
  },
  methods: {
    removeInput() {
      this.$emit('removeDoc')
    },
    reset() {
      this.documents = []
      this.state = 'init'
      this.lastVisible = null
      this.firstVisible = null
      this.get()
    },
    cleanData() {
      this.saveToDocuments = null
      this.fileToUpload = null
      this.tabs = 0
      this.searchInput = null
      this.selectedDocument = null
    },
    // Función que le da formato a una fecha
    formatDate(val) {
      if (val != null) {
        const isValidDate = Date.parse(val)

        if (!isNaN(isValidDate)) {
          val = new Date(val)
          return format(new Date(val), 'dd/MM/yyyy')
        } else {
          return format(
            new Date((val.seconds || val._seconds) * 1000),
            'dd/MM/yyyy'
          )
        }
      }
    },
    async get() {
      const self = this
      this.loading = true
      let query = null

      let ref = null

      let collectionName = this.documentType

      let path = ''

      if (this.user.company) {
        path = 'companies'
      } else {
        path = 'users'
      }

      // Si se esta inicializando, y se estan buscando los certificados
      // agregamos el certificado de identidad digital
      if (
        this.documentType === 'certs' &&
        this.state === 'init' &&
        this.firstVisible === null &&
        this.lastVisible === null
      ) {
        await self.$fire.firestore
          .collection('certificates')
          .where('id_user', '==', self.user.uid)
          .where('is_certified', '==', true)
          .limit(1)
          .get()
          .then((certIdentidad) => {
            if (certIdentidad.docs.length > 0) {
              self.documents.push({
                ...certIdentidad.docs[0].data(),
                title: certIdentidad.docs[0].data().title,
                createdAt: certIdentidad.docs[0].data().created,
                type: self.$t('identidad-digital'),
                statusDocument: 'Vigente',
                size: 0,
                mime: 'application/pdf',
              })
            }
          })
      }

      if (this.documentType === 'certs') {
        collectionName = 'documentsFinals'
      }

      if (self.user.uid) {
        ref = this.$fire.firestore
          .collection(path)
          .doc(self.user.uid)
          .collection(collectionName)
          .where('statusDocument', '==', 'Vigente')
          .orderBy('createdAt', 'desc')

        if (this.searchInput !== null) {
          ref = ref.where('title', '==', this.searchInput)
        }

        if (this.documentType === 'certs') {
          ref = ref.where('is_certified', '==', true)
        }
      }

      if (ref !== null) {
        if (this.state === 'init') {
          query = ref.endBefore(this.lastVisible).limit(this.itemsPerPage)
        } else if (this.state === 'next' && this.firstVisible) {
          query = ref.startAfter(this.lastVisible).limit(this.itemsPerPage)
        }

        await query
          .get()
          .then((querySnapshot) => {
            this.firstVisible = querySnapshot.docs[0]
            this.lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1]

            if (
              querySnapshot.docs.length < this.itemsPerPage ||
              querySnapshot.docs.length === 0
            ) {
              this.disableLoad = true
            } else {
              this.disableLoad = false
            }

            if (collectionName === 'documentsFinals') {
              querySnapshot.forEach(function (doc) {
                self.documents.push({
                  id: doc.id,
                  ...doc.data(),
                  mime: 'application/pdf',
                  size: 0,
                })
              })
            } else {
              querySnapshot.forEach(function (doc) {
                self.documents.push({ id: doc.id, ...doc.data() })
              })
            }

            self.loading = false
          })
          .catch((error) => {
            self.loading = false
            // eslint-disable-next-line no-console
            console.error(this.$t('error-getting-documents'), error)
          })
      }
    },
    save() {
      if (this.tabs === 'tab-0') {
        this.$emit('saveDoc', {
          file: this.selectedDocument,
          selectedOption: 'portadocumentos',
          documentType: this.documentType,
        })
      } else {
        this.$emit('saveDoc', {
          file: this.fileToUpload,
          selectedOption: 'pc',
          saveToDocuments: this.saveToDocuments,
          documentName: this.documentName,
        })
      }
      this.cleanData()
    },
  },
}
