
export default {
  name: 'InvalidApiDialog',
  components: {},
  props: {
    invalidApiDialog: { type: Boolean, default: false },
    errorType: { type: String, default: '' },
    question: { type: Object, default: () => {} },
  },
  computed: {
    openDialog() {
      return this.invalidApiDialog
    },
    allowRequestWithDeuda() {
      return this.question.allowDeuda
    },
  },
  methods: {},
}
