export default function ({ store, redirect }) {
  if (
    store.state.loggedIn &&
    !store.state.users.user.public_address &&
    store.state.users.user.type &&
    store.state.users.user.type !== 'superadmin'
  ) {
    return redirect('/auth/CompleteData')
  }
}
