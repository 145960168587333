export default function ({ store, redirect }) {
  if (store.state.loggedIn && !isSuperadmin(store.state.users.user)) {
    return redirect('/forbidden')
  }
}

// Vistas abiertas solo para 'superadmin'
function isSuperadmin(user) {
  return user && user.role && user.role === 'superadmin'
}
