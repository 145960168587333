import { getFunctions, httpsCallable } from 'firebase/functions'
import { getApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { isTokenExpired } from '@/services/Utils'
const region = process.env.REGION || 'us-central1'
const urlIDE = process.env.URL_API_IDE
const urlIDERegistro = process.env.URL_API_IDE_REGISTRO

const ide = async (config: any) => {
  let body = {
    type: config.method,
    url: `${urlIDE}/${config.url}`,
  }
  if (config.data) {
    body = Object.assign({ data: config.data }, body)
  }

  const functions = getFunctions(getApp(), region)
  const ide = httpsCallable(functions, 'mty-instances-ide')
  return await ide({
    ...body,
  })
    .then((response: any) => {
      if (response.data && response.data.data) return response.data.data
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Ocurrió un error')
      throw new Error(error)
    })
}

const ideApiCarto = async (config: any) => {
  const body = {
    params: config.data,
    type: 'GET',
    url: `https://admin.ec.appsmty.gob.mx/apiCarto/pdu/estacionamientosPorUso`,
  }

  const functions = getFunctions(getApp(), region)
  const ide = httpsCallable(functions, 'mty-instances-ide')
  return await ide({
    ...body,
  })
    .then((response: any) => {
      if (response.data && response.data.data) return response.data.data
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Ocurrió un error')
      throw new Error(error)
    })
}
const ideMIDE = async (config: any) => {
  let body = {
    type: config.method,
    url: `${urlIDERegistro}/registro-tramite-${config.url}/`,
  }
  if (config.data) {
    body = Object.assign({ data: config.data }, body)
  }

  if (process.browser) {
    const userToken =
      window.$nuxt.$store.getters['users/user'].token ||
      localStorage.getItem('mtyuserToken')
    // Set Authorization token
    if (userToken) {
      const isAccesstokenExpired = isTokenExpired(userToken)
      const tokenToUse = isAccesstokenExpired
        ? await getAuth().currentUser?.getIdToken(true)
        : userToken
      // config.headers.Authorization = `Bearer ${userToken}`
      body = Object.assign({ token: tokenToUse }, body)
    }
  }

  const functions = getFunctions(getApp(), region)
  const ideFunction = httpsCallable(functions, 'mty-instances-ide')
  return await ideFunction({
    ...body,
  })
    .then((response: any) => {
      if (response.data && response.data.data) {
        return response.data
      } else if (response.data) {
        return response.data
      }
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Ocurrió un error')
      throw new Error(error)
    })
}

export default { ide, ideApiCarto, ideMIDE }
