const evaluateRedirect = function (registerFlag, redirect, route, resolve) {
  if (registerFlag === true) {
    if (route.path === '/auth/login') {
      redirect('/login')
    } else if (route.path === '/auth/register') {
      redirect('/register')
    } else if (route.path === '/auth/completeData') {
      redirect('/complete-data')
    } else if (route.path === '/auth/resetPassword') {
      redirect('/reset-password')
    } else {
      resolve()
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if (route.path === '/login') {
      redirect('/auth/login')
    } else if (route.path === '/register') {
      redirect('/auth/register')
    } else if (route.path === '/complete-data') {
      redirect('/auth/completeData')
    } else if (route.path === `/auth`) {
      redirect(`/auth/register`)
    } else if (route.path === '/reset-password') {
      redirect('/auth/resetPassword')
    } else {
      resolve()
    }
  }
}

export default function ({ store, redirect, route }) {
  return new Promise((resolve) => {
    if (store.getters['authconfig/getOldRegister'] === null) {
      store.dispatch('authconfig/get').then(() => {
        evaluateRedirect(
          store.getters['authconfig/getOldRegister'],
          redirect,
          route,
          resolve
        )
      })
    } else {
      evaluateRedirect(
        store.getters['authconfig/getOldRegister'],
        redirect,
        route,
        resolve
      )
    }
  })
}
