import * as _ from 'lodash'
import { getAllModules } from '@/services/Modules'

export const state = () => ({
  modules: [],
})

export const mutations = {
  SET_MODULES(state, modules) {
    state.modules = _.cloneDeep(modules)
  },
}

export const actions = {
  async get({ commit }) {
    try {
      const result = await getAllModules(this.$fire)
      const modules = []
      if (!result.empty) {
        result.forEach((doc) => {
          if (doc.data().alias) {
            modules.push({ id: doc.id, ...doc.data() })
          }
        })
      }
      commit('SET_MODULES', modules)
    } catch (error) {
      console.log(error)
      throw new Error(error)
    }
  },
}

export const getters = {
  getModules(state) {
    return state.modules
  },
  getActiveModules(state) {
    const activeModules = state.modules.filter(
      (modules) => modules.active === true
    )
    return activeModules
  },
}
