
export default {
  name: 'Footer',
  data: () => ({
    btnRedes: [
      {
        icon: require('@/assets/images/bannernl/facebook.svg'),
        link: 'https://www.facebook.com/gobiernonuevoleon/',
      },
      {
        icon: require('@/assets/images/bannernl/instagram.svg'),
        link: 'https://www.instagram.com/nuevoleonmx/',
      },
      {
        icon: require('@/assets/images/bannernl/twitter.svg'),
        link: 'https://twitter.com/nuevoleon',
      },
      {
        icon: require('@/assets/images/bannernl/tiktok.svg'),
        link: 'https://www.tiktok.com/@nuevonlmx',
      },
      {
        icon: require('@/assets/images/bannernl/youtube.svg'),
        link: 'https://www.youtube.com/user/GobiernoNuevoLeon',
      },
    ],
  }),
}
