export const state = () => ({
  show: false,
})

export const mutations = {
  SHOW_DIALOG(state) {
    state.show = true
  },
  CLOSE_DIALOG(state) {
    state.show = false
  },
}

export const actions = {
  open({ commit }, payload) {
    commit('SHOW_DIALOG', payload)
  },
  close({ commit }) {
    commit('CLOSE_DIALOG')
  },
}
