export const state = () => ({
  collaborator: [],
})

export const mutations = {
  SET_COLLABORATOR(state, collaborator) {
    state.collaborator = collaborator
  },
}

export const actions = {
  async get({ commit, rootState }) {
    if (rootState.user.uid) {
      await this.$fire.firestore
        .collectionGroup('collaborators')
        .where('uid', '==', rootState.user.uid)
        .get()
        .then((response) => {
          if (!response.empty) {
            this.items = response.docs.map((doc) => {
              return {
                ...doc.data(),
                id: doc.id,
              }
            })
          }
        })
        .finally(() => {
          commit('SET_COLLABORATOR', this.items)
        })
    }
  },
}

export const getters = {
  getCollaborator(state) {
    return state.collaborator
  },
  getCollaboratorByModule: (state) => (moduleId, submoduleId) => {
    let collaborator = {}
    let collaborators = []
    if (state.collaborator) {
      // Filtrar todos los registron en collaborators donde esta el usuario que correspondan al módulo
      collaborators = state.collaborator.filter((element) => {
        return element.module === moduleId
      })
      if (collaborators.length > 1) {
        // Buscar si de los anteriores existe el usuario a nivel módulo - Tiene acceso a todo el módulo
        collaborator = collaborators.find((element) => {
          return !element.submodule
        })
        // Si no existe el usuario a nivel módulo, buscar el que corresponda al submódulo correspondiente
        if (!collaborator) {
          collaborator = collaborators.find((element) => {
            return (
              element.module === moduleId && element.submodule === submoduleId
            )
          })
        }
      } else {
        collaborator = collaborators[0]
      }
    }
    return collaborator
  },
}
