import { getCertsConfig } from '@/services/CertsConfig'

export const state = () => ({
  certsConfig: {
    identity: 'info@os.city',
    name: 'Certificados Os City',
    pubkey: '0xa854cc517c45c806073e1ca7fbd082b63837e332',
    manualUpload: false,
    dataLoadingByForm: false,
    certsCollectionsToRevoke: ['certificates'],
    withAuthorized: false,
    swearDeclare: [],
  },
})

export const mutations = {
  SET_CERTS_CONFIG(state, certsConfig) {
    state.certsConfig = certsConfig
  },
}

export const actions = {
  async get({ commit, rootState }) {
    // const self = this
    await getCertsConfig(this.$fire).then((res) => {
      let certsConfig = rootState.certsConfig.certsConfig
      if (!res.empty) {
        certsConfig = res.docs[0].data()
        commit('SET_CERTS_CONFIG', certsConfig)
      }
      // else {
      //   await self.$axios
      //     .$post('/saveCollectionData', {
      //       collection: 'certsConfig',
      //       data: certsConfig,
      //     })
      //     .catch((err) => {
      //       // eslint-disable-next-line no-console
      //       console.error(err)
      //     })
      // }
      /* @TODO: Bug documentos dobles en colecciones (firebase) */
      // else {
      //   const urlEndpoints = process.env.OSCITY_ENDPOINTS_URL
      //   await axios.post(
      //     `${urlEndpoints}/saveCollectionData`,
      //     {
      //       collection: 'certsConfig',
      //       data: certsConfig,
      //     }
      //   )
      // }
    })
  },
}

export const getters = {
  certsConfig(state) {
    return state.certsConfig
  },
  manualUpload(state) {
    return state.certsConfig.manualUpload
  },
  isLoadingByForm(state) {
    return state.certsConfig.dataLoadingByForm
  },
  pubkey(state) {
    return state.certsConfig.pubkey
  },
  getCertsCollectionsToRevoke(state) {
    return state.certsConfig.certsCollectionsToRevoke
  },
}
