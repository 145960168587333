
import Vue from 'vue'

export default Vue.extend({
  name: 'Logo',
  components: {},
  props: {
    heightImage: {
      type: String,
      required: false,
      default: '',
    },
    imageUrl: {
      type: String,
      required: false,
      default: '',
    },
    position: {
      type: String,
      required: false,
      default: 'center',
    },
  },
  computed: {
    heightLogo() {
      return this.heightImage
    },
  },
})
