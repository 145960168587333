export default function ({ store, redirect }) {
  return new Promise((resolve) => {
    store.dispatch('authconfig/get').then(() => {
      if (!store.getters['authconfig/register']) {
        redirect('/login')
      } else {
        resolve()
      }
    })
  })
}
