import * as _ from 'lodash'
import {
  getAllPaymentConcepts,
  getActivatePaymentMethods,
} from '@/services/Sales'

export const state = () => ({
  paymentConcepts: [],
})

export const mutations = {
  SET_PAYMENTCONCEPTS(state, paymentConcepts) {
    state.paymentConcepts = _.cloneDeep(paymentConcepts)
  },
}

export const actions = {
  async get({ commit }) {
    try {
      const result = await getAllPaymentConcepts(this.$fire)
      const paymentConcepts = []
      if (!result.empty) {
        result.forEach((doc) => {
          paymentConcepts.push({ id: doc.id, ...doc.data() })
        })
      }
      commit('SET_PAYMENTCONCEPTS', paymentConcepts)
    } catch (error) {
      // @todo Handle Error
      console.log(error)
      throw new Error(error)
    }
  },
  async getActive({ commit }) {
    try {
      const result = await getActivatePaymentMethods(this.$fire)
      const paymentConcepts = []
      if (!result.empty) {
        result.forEach((doc) => {
          paymentConcepts.push({ id: doc.id, ...doc.data() })
        })
      }
      commit('SET_PAYMENTCONCEPTS', paymentConcepts)
    } catch (error) {
      // @todo Handle Error
      console.log(error)
      throw new Error(error)
    }
  },
}

export const getters = {
  getAllPaymentConcepts(state) {
    return state.paymentConcepts
  },
}
