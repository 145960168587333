import { includes } from 'lodash'

export default function ({ store, redirect }) {
  if (store.state.loggedIn && !isAdmin(store.state.users.user)) {
    return redirect('/forbidden')
  }
}

// Vistas abiertas solo para 'superadmin', 'admin', 'editor' - Operadores no ven éstas vistas
function isAdmin(user) {
  return (
    user && user.role && includes(['superadmin', 'admin', 'editor'], user.role)
  )
}
