import * as _ from 'lodash'
import { getAllRoles } from '@/services/Roles'

export const state = () => ({
  roles: [],
})

export const mutations = {
  SET_ROLES(state, roles) {
    state.roles = _.cloneDeep(roles)
  },
}

export const actions = {
  async get({ commit }) {
    try {
      const result = await getAllRoles(this.$fire)
      const roles = []
      if (!result.empty) {
        result.forEach((doc) => {
          roles.push({ id: doc.id, ...doc.data() })
        })
      }
      commit('SET_ROLES', roles)
    } catch (error) {
      // @todo Handle Error
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(error)
    }
  },
}

export const getters = {
  getRoles(state) {
    return state.roles
  },
}
