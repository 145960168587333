
import Vue from 'vue'
/* eslint-disable no-undef */
/* eslint-disable vue/prop-name-casing */

import { Loader } from '@googlemaps/js-api-loader'
import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import cloneDeep from 'lodash/cloneDeep'

export default Vue.extend({
  name: 'Address',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    updateMap: {
      type: Boolean,
      default: false,
    },
    resetMap: {
      type: Boolean,
      default: false,
    },
    typeOfMap: {
      type: Boolean,
      default: false,
    },
    labelField: {
      type: String,
      default: '',
      required: false,
    },
    rules: {
      type: Array,
      default: () => [],
      required: false,
    },
    location: {
      type: Object,
      default: () => ({
        cp: null,
        lat: null,
        lng: null,
        city: null,
        state: null,
        street: null,
        reference: null,
        neighborhood: null,
        outdoorNumber: null,
        interiorNumber: null,
      }),
    },
  },
  data() {
    return {
      inputId: 'input-' + Math.random(),
      mapId: 'map-' + Math.random(),
      map: null,
      marker: null,
      geocoder: null,
      autocomplete: null,
      search: null,
      polygon: null,
      polygonId: null,
      center: {
        lat: this.lat ? this.lat : 25.6704575,
        lng: this.lng ? this.lng : -100.3109926,
      },
    }
  },
  computed: {
    componentLocation() {
      return cloneDeep(this.location)
    },
    lat() {
      return this.location.lat
    },
    lng() {
      return this.location.lng
    },
  },
  watch: {
    lat(val) {
      // @todo Validate with @Chepe
      if (val !== null) {
        this.center = {
          lat: this.lat,
          lng: this.lng,
        }
      }
    },
    resetMap(val) {
      if (val !== null) {
        this.center = {
          lat: 25.6704575,
          lng: -100.3109926,
        }
        this.initMap()
      }
    },
    updateMap: {
      handler(val) {
        if (val !== null) {
          this.addPolygon()
        }
      },
    },
    location(val) {
      if (val.lat !== null) {
        this.center = {
          lat: val.lat,
          lng: val.lng,
        }
        this.initMap()
      }
    },
  },
  beforeMount() {
    this.mapId = 'map-' + Math.random()
  },
  async mounted() {
    await this.initMap()
  },
  methods: {
    async initMap() {
      await new Loader({
        apiKey: `${process.env.GOOGLE_API_KEY}`,
        version: 'weekly',
        libraries: ['places'],
      })
        .load()
        .then((google) => {
          if (document.getElementById(this.mapId)) {
            this.map = null
            this.map = new google.maps.Map(
              document.getElementById(this.mapId),
              {
                center: this.center,
                zoom: 15,
              }
            )
            this.marker = new google.maps.Marker({
              map: this.map,
              draggable: !this.disabled, // Editable view
              position: this.center,
            })

            this.geocoder = new google.maps.Geocoder()
            const input = document.getElementById(this.inputId)
            this.autocomplete = new google.maps.places.Autocomplete(input, {
              fields: ['formatted_address', 'geometry', 'name'],
            })
            this.marker.addListener('dragend', this.coordinates)
            this.autocomplete.bindTo('bounds', this.map)
            this.autocomplete.addListener('place_changed', this.placeChanged)
          }
        })
        .catch((error) => {
          throw new Error(error)
        })
    },
    addPolygon() {
      const vm = this
      if (this.claveUso) {
        if (this.polygon !== null) {
          this.map.data?.forEach(function (feature) {
            if (feature.getId() === vm.polygonId) {
              vm.map.data.remove(feature)
            }
          })
        }

        this.polygon = this.map.data?.addGeoJson(this.claveUso)
        this.polygonId = this.claveUso.features[0].properties.ecarto_id

        this.map.setZoom(18)
        this.map.setCenter({ lat: this.lat, lng: this.lng })
        this.marker.setPosition({ lat: this.lat, lng: this.lng })
        if (!this.typeOfMap) {
          setTimeout(() => {
            this.coordinates({ lat: this.lat, lng: this.lng }, 'latlng')
          }, 500)
        }
      }
    },
    placeChanged() {
      let address = ''
      const self = this
      const place = this.autocomplete.getPlace()
      if (place.formatted_address) {
        address = place.formatted_address
      } else {
        address = place.name
      }
      this.geocoder.geocode({ address }, function (results, status) {
        if (status !== 'OK') {
          window.alert('Geocoder failed due to: ' + status)
          return
        }
        self.map.setZoom(15)
        self.map.setCenter(results[0].geometry.location)
        self.marker.setPosition(results[0].geometry.location)
        self.coordinates(results[0].geometry.location, 'autocomplete')
      })
    },
    coordinates(event, from) {
      const self = this
      let location

      if (from === 'autocomplete') {
        self.componentLocation.lat = event.lat()
        self.componentLocation.lng = event.lng()
        self.$emit('handlerLocation', self.componentLocation)
        location = event
      } else if (from === 'latlng') {
        location = event
      } else {
        self.componentLocation.lat = event.latLng.lat()
        self.componentLocation.lng = event.latLng.lng()
        self.$emit('handlerLocation', this.componentLocation)
        location = event.latLng
      }

      this.geocoder.geocode({ location }, function (results) {
        const filteredResults = filter(results, function (result) {
          return (
            result.types[0] === 'street_address' || result.types[0] === 'route'
          )
        })
        if (filteredResults.length > 0) {
          forEach(filteredResults[0].address_components, function (el) {
            if (el.types.includes('route')) {
              self.componentLocation.street = el.long_name
            }
            if (el.types.includes('sublocality')) {
              self.componentLocation.neighborhood = el.long_name
            }
            if (el.types.includes('street_number')) {
              self.componentLocation.outdoorNumber = el.long_name
            }
            if (el.types.includes('postal_code')) {
              self.componentLocation.cp = el.long_name
            }
            if (el.types.includes('administrative_area_level_1')) {
              self.componentLocation.state = el.long_name
            }
            if (el.types.includes('locality')) {
              self.componentLocation.city = el.long_name
            }
          })
          self.$emit('handlerLocation', self.componentLocation)
        }
      })
    },
    updateLocation(event, key) {
      this.componentLocation[key] = event
      this.$emit('handlerLocation', this.componentLocation)
    },
  },
})
