
import { mapGetters } from 'vuex'
import Address from '@/components/Map/Address.vue'
export default {
  // eslint-disable-next-line vue/component-definition-name-casing
  name: 'v-custom-addressf',
  components: { Address },
  // eslint-disable-next-line vue/require-prop-types
  props: ['type', 'value', 'obj'],
  data() {
    return {
      resetMap: null,
      updateMap: null,
      switchCodeOrMap: false,
      location: {
        cp: null,
        lat: null,
        lng: null,
        city: null,
        state: null,
        street: null,
        reference: null,
        neighborhood: null,
        outdoorNumber: null,
        interiorNumber: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      platformLatLng: 'setting/platformLatLng' || [
        16.49847115994006, -76.22896135,
      ],
    }),
    rules() {
      let rules = []
      if (this.value === null || this.value === undefined) {
        rules = [!!this.value || this.$t('required')]
      }
      return rules
    },
    input: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    labelField() {
      if (this.obj && this.obj.schema) {
        return this.obj.schema.label || this.obj.schema.name
      }
      return ''
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.location = val
          ? { ...val }
          : {
              cp: null,
              lat: null,
              lng: null,
              city: null,
              state: null,
              street: null,
              reference: null,
              neighborhood: null,
              outdoorNumber: null,
              interiorNumber: null,
            }
      }
    },
  },
  mounted() {
    this.switchCodeOrMap = this.value?.switchCodeOrMap || false
    this.resetMap = this.value?.resetMap || null
    this.location = this.value
      ? { ...this.value }
      : {
          cp: null,
          lat: this.platformLatLng[0],
          lng: this.platformLatLng[1],
          city: null,
          state: null,
          street: null,
          reference: null,
          neighborhood: null,
          outdoorNumber: null,
          interiorNumber: null,
        }
  },
  methods: {
    save(location) {
      this.$emit('input', {
        ...location,
      })
    },
    handlerLocation(event) {
      this.location = Object.assign({}, event)
      this.save(event)
    },
    cleanData() {
      this.location = {
        cp: null,
        lat: null,
        lng: null,
        city: null,
        state: null,
        street: null,
        reference: null,
        neighborhood: null,
        outdoorNumber: null,
        interiorNumber: null,
      }
      this.resetMap = !this.resetMap
    },
  },
}
