import { setDocFirestore } from '@/services/firebaseWrite'

export const getLandingText = async ($fire: any, id: String) => {
  return await $fire.firestore.collection('landingText').doc(id).get()
}

export const getLastLandingText = async ($fire: any) => {
  return await $fire.firestore.collection('landingText').limit(1).get()
}

export const setLandingText = async ($fire: any, data: any) => {
  return await $fire.firestore.collection('landingText').add(data)
}

export const updateLandingText = async ($fire: any, id: String, body: any) => {
  // const data = { ...body }
  // return await $fire.firestore
  //   .collection('landingText')
  //   .doc(id)
  //   .set(data, { merge: true })
  return await setDocFirestore($fire, `landingText/${id}`, { ...body }, true)
}

export const getCertificates = async ($fire: any) => {
  return await $fire.firestore
    .collection('certificates')
    .get()
    .then((doc: any) => {
      return doc
    })
}
