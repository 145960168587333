import InstanceIde from '@/services/apis/ide'

export const getClaveUsoPorExpediente = async (
  $axios: any,
  gtoken: string,
  expediente: string
) => {
  return await InstanceIde.ide({
    url: 'pdu/uso-suelo-expediente/',
    data: {
      recaptcha_token: gtoken,
      expediente,
    },
    method: 'POST',
  })

  /* return await $axios
      .$post(
      'https://ecartoapi.drf.mun.apismty.gob.mx/rest/v1/pdu/densidad-habitacional/', {
      'recaptcha_token': 'recaptcha_token',
      'clave_uso': 'HMM',
      'clave_densidad': 'D1'
    }) */

  /* .$get(
    //'https://admin.ec.appsmty.gob.mx/apiCarto/pdu/usoporexpediente?'
    'https://ide.api.monterrey.gob.mx/rest/v1/proxy-ecarto/pdu/usoporexpediente?'
      + 'expediente='+ expediente
      //+ '&apikey=V7sXnaHVS9m6H54%6xh!qqFC'
  ) */

  /* return new Promise((resolve) =>{
    resolve(
      {
        "expediente": "7008079025",
        "area": "594.97",
        "claveUsoSuelo": "HMM",
        "usoDeSuelo": "HMM - Habitacional Mixto Medio",
        "claveCorredor": "",
        "corredor": "",
        "usoDeSueloTec": "",
        "claveDistrito": "CMC",
        "distrito": "Centro - Mitras Centro",
        "densidad": "D10",
        "latitud": "25.6704575",
        "longitud": "-100.3109926"
      }
    )
  }) */
}

export const getClaveUsoPorLatLng = async (
  $axios: any,
  gtoken: string,
  latitud: number,
  longitud: number
) => {
  return await InstanceIde.ide({
    url: 'pdu/uso-suelo-coordenadas/',
    data: {
      recaptcha_token: gtoken,
      latitud,
      longitud,
    },
    method: 'POST',
  })

  /* .$get(
    'https://ide.api.monterrey.gob.mx/rest/v1/proxy-ecarto/pdu/usoporcoordenada?'
    + 'latitud='+ latitud
    + '&longitud='+ longitud
  ) */

  /* return new Promise((resolve) =>{
    setTimeout(() => {
      resolve(
        {
          "expediente": "7008079025",
          "area": "594.97",
          "claveUsoSuelo": "HMM",
          "usoDeSuelo": "HMM - Habitacional Mixto Medio",
          "claveCorredor": "",
          "corredor": "",
          "usoDeSueloTec": "",
          "claveDistrito": "CMC",
          "distrito": "Centro - Mitras Centro",
          "densidad": "D10",
          "latitud": "25.689240476580558",
          "longitud": "-100.32783767426736"
        }
      )
    }, 500);
  })  */
}

export const getGirosPorUso = async (
  $axios: any,
  gtoken: string,
  claveUso: string
) => {
  return await InstanceIde.ide({
    url: 'pdu/giro-por-uso/',
    data: {
      recaptcha_token: gtoken,
      clave_uso: claveUso,
    },
    method: 'POST',
  })

  /*  .$get(
    //'https://admin.ec.appsmty.gob.mx/apiCarto/pdu/girosporuso?'
    'https://ide.api.monterrey.gob.mx/rest/v1/proxy-ecarto/pdu/girosporuso?'
    + 'clave_uso='+ claveUso
    //+ '&apikey=V7sXnaHVS9m6H54%6xh!qqFC'
  ) */

  /* return new Promise((resolve)=>{
      resolve(
        [
          {
              "claveGrupo": "1.1",
              "giro": "Unifamiliar",
              "compatibilidad": "PERMITIDO"
          }
        ]
      )
    })
  */
}

export const getDensidadHabitacional = async (
  $axios: any,
  gtoken: string,
  claveUso: string,
  claveDensidad: string
) => {
  return await InstanceIde.ide({
    url: 'pdu/densidad-habitacional/',
    data: {
      recaptcha_token: gtoken,
      clave_uso: claveUso,
      clave_densidad: claveDensidad,
    },
    method: 'POST',
  })

  /*  .$get(
      //'https://admin.ec.appsmty.gob.mx/apiCarto/pdu/densidadhabitacional?'
      'https://ide.api.monterrey.gob.mx/rest/v1/proxy-ecarto/pdu/densidadhabitacional?'
      + 'clave_uso='+ claveUso
      + '&clave_densidad='+ claveDensidad
      //+ '&apikey=V7sXnaHVS9m6H54%6xh!qqFC'
    ) */

  /* return new Promise( resolve =>{
    resolve(
      {
        "viviendaHabitante": "8",
        "cos": "0.4",
        "cus": "0.7",
        "cav": "0.3"
      }
    )
  }) */
}

export const getDensidadComercialesServicios = async (
  $axios: any,
  gtoken: string,
  claveUso: string
) => {
  return await InstanceIde.ide({
    url: 'pdu/densidad-comercial-servicio/',
    data: {
      recaptcha_token: gtoken,
      clave_uso: claveUso,
    },
    method: 'POST',
  })

  /* .$get(
      'https://ide.api.monterrey.gob.mx/rest/v1/proxy-ecarto/pdu/densidadcomercialesyservicios?'
      + 'clave_uso='+ claveUso
      + '&clave_giro='+ claveGiro
      //+ '&apikey=V7sXnaHVS9m6H54%6xh!qqFC'
    ) */
}

export const getDensidadCrecimientoControlado = async (
  $axios: any,
  gtoken: string,
  claveUso: string,
  pendiente: string
) => {
  return await InstanceIde.ide({
    url: 'pdu/densidad-crecimiento-controlado/',
    data: {
      recaptcha_token: gtoken,
      clave_uso: claveUso,
      pendiente,
    },
    method: 'POST',
  })

  /* .$get(
      'https://ide.api.monterrey.gob.mx/rest/v1/proxy-ecarto/pdu/densidadcrecimientocontrolado?'
      + 'clave_uso='+ claveUso
      + '&pendiente='+ pendiente
      //+ '&apikey=V7sXnaHVS9m6H54%6xh!qqFC'
    ) */
}

export const getDensidadCorredoresUrbanos = async (
  $axios: any,
  gtoken: string,
  claveUso: string
) => {
  return await InstanceIde.ide({
    url: 'pdu/corredor-urbano/',
    data: {
      recaptcha_token: gtoken,
      clave_uso: claveUso,
    },
    method: 'POST',
  })

  /* .$get(
      'https://ide.api.monterrey.gob.mx/rest/v1/proxy-ecarto/pdu/densidadcorredoresurbanos?'
      + 'clave_uso='+ claveUso
      //+ '&apikey=V7sXnaHVS9m6H54%6xh!qqFC'
    ) */
}

export const getDensidadCorredoresUrbanosArea = async (
  $axios: any,
  gtoken: string,
  claveUso: string,
  area: string
) => {
  return await InstanceIde.ide({
    url: 'pdu/corredor-urbano-area/',
    data: {
      recaptcha_token: gtoken,
      clave_uso: claveUso,
      area,
    },
    method: 'POST',
  })

  /* .$get(
      'https://ide.api.monterrey.gob.mx/rest/v1/proxy-ecarto/pdu/densidadcorredoresurbanosarea?'
      + 'clave_uso='+ claveUso
      + '&area='+ area
    ) */
}

export const getDensidadSubcentrosUrbanos = async (
  $axios: any,
  gtoken: string,
  claveUso: string,
  area: string
) => {
  return await InstanceIde.ide({
    url: 'pdu/densidad-subcentro-urbano/',
    data: {
      recaptcha_token: gtoken,
      clave_uso: claveUso,
      area,
    },
    method: 'POST',
  })

  /* .$get(
      'https://ide.api.monterrey.gob.mx/rest/v1/proxy-ecarto/pdu/densidadsubcentrosurbanos?'
      + 'clave_uso='+ claveUso
      + '&area='+ area
    ) */
}

export const getZonaConservacion = async (
  $axios: any,
  gtoken: string,
  latitud: number,
  longitud: number
) => {
  return await InstanceIde.ide({
    url: 'pdu/zona-conservacion/',
    data: {
      recaptcha_token: gtoken,
      latitud,
      longitud,
    },
    method: 'POST',
  })

  /* .$get(
      //'https://admin.ec.appsmty.gob.mx/apiCarto/pdu/centrometropolitano?'
      'https://ide.api.monterrey.gob.mx/rest/v1/proxy-ecarto/pdu/centrometropolitano?'
      + 'latitud='+ latitud
      + '&longitud='+ longitud
      //+ '&apikey=V7sXnaHVS9m6H54%6xh!qqFC'
    ) */

  /* return new Promise(resolve => {
    resolve(
      {
        "intersecta": true
      }
    )
  }) */
}

export const getCentroMetropolitano = async (
  $axios: any,
  gtoken: string,
  latitud: number,
  longitud: number
) => {
  return await InstanceIde.ide({
    url: 'pdu/centro-metropolitano/',
    data: {
      recaptcha_token: gtoken,
      latitud,
      longitud,
    },
    method: 'POST',
  })

  /* .$get(
      //'https://admin.ec.appsmty.gob.mx/apiCarto/pdu/centrometropolitano?'
      'https://ide.api.monterrey.gob.mx/rest/v1/proxy-ecarto/pdu/centrometropolitano?'
      + 'latitud='+ latitud
      + '&longitud='+ longitud
      //+ '&apikey=V7sXnaHVS9m6H54%6xh!qqFC'
    ) */

  /* return new Promise(resolve => {
    resolve(
      {
        "intersecta": true
      }
    )
  }) */
}

export const estacionamientosPorUso = async (
  $axios: any,
  token: string,
  claveDistrito: string
) => {
  return await InstanceIde.ideApiCarto({
    params: {
      clave_distrito: claveDistrito,
      apikey: 'V7sXnaHVS9m6H54%6xh!qqFC',
    },
    method: 'GET',
  })
}
