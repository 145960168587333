import { find } from 'lodash'

export default function ({ store, redirect, route }) {
  // Valida si usuario esta logueado y es superadmin
  if (store.state.loggedIn && !isSuperAdmin(store.state.users.user)) {
    // Si no es superadmin pero si es funcionario valida si tiene permitido ver el módulo
    if (
      store.state.loggedIn &&
      isOfficer(store.state.users.user) &&
      !isAllowedModule(store.state.users.user, route)
    ) {
      return redirect('/forbidden')
    } else if (store.state.loggedIn && !isOfficer(store.state.users.user)) {
      return redirect('/forbidden')
    }
  }
}

function isAllowedModule(user) {
  const moduleId = 'appointments'
  const result = find(user.availableModules, (availableModule) => {
    return availableModule.module === moduleId
  })
  let isValid = false
  if (result && result.role === 'admin') {
    isValid = true
  }
  return isValid
}

function isOfficer(user) {
  return user && user.auxRole === 'officer'
}

function isSuperAdmin(user) {
  return user && user.role && user.role === 'superadmin'
}
