import { cloneDeep, concat } from 'lodash'

import { getCatalogs } from '@/services/Catalogs'

export const state = () => ({
  catalogs: [],
})

export const mutations = {
  SET_CATALOGS(state, catalogs) {
    state.catalogs = cloneDeep(catalogs)
  },
  ADD_CATALOG_TO_ARRAY(state, catalog) {
    state.catalogs = concat(state.catalogs, catalog)
  },
  UPDATE_CATALOG(state, catalog) {
    state.catalogs = state.catalogs.map((element) => {
      return element.id === catalog.id ? catalog : element
    })
  },
  REMOVE_CATALOG(state, catalog) {
    let index = null
    index = state.catalogs.findIndex((element) => {
      return element.id === catalog.id
    })
    if (index !== -1) state.catalogs.splice(index, 1)
  },
}

export const actions = {
  async get({ commit }) {
    const catalogsArray = []
    await getCatalogs(this.$fire).then((documents) => {
      if (!documents.empty) {
        documents.forEach((doc) => {
          const data = {
            id: doc.id,
            reference: doc.ref.path,
            ...doc.data(),
          }
          const val = data.created

          data.createdDate = val
          catalogsArray.push(data)
        })

        commit('SET_CATALOGS', catalogsArray)
      }
    })
  },
  addCatalog({ commit }, payload) {
    commit('ADD_CATALOG_TO_ARRAY', payload)
  },
  updateCatalog({ commit }, payload) {
    commit('UPDATE_CATALOG', payload)
  },
  removeCatalog({ commit }, payload) {
    commit('REMOVE_CATALOG', payload)
  },
}

export const getters = {
  getCatalogs(state) {
    return state.catalogs
  },
  getCatalogById: (state) => (id) => {
    return state.catalogs.find((catalog) => catalog.id === id)
  },
}
