import { find, includes } from 'lodash'

export default function ({ store, redirect, route }) {
  // Valida si usuario esta logueado y es superadmin
  if (store.state.loggedIn && !isSuperAdmin(store.state.users.user)) {
    // Si no es superadmin pero es admin valida si tiene permitido ver el submodulo
    if (
      store.state.loggedIn &&
      (isAdmin(store.state.users.user) ||
        isVisualizer(store.state.users.user)) &&
      !isAllowedSubmodule(store.state.users.user, route)
    ) {
      return redirect('/forbidden')
    } else if (
      store.state.loggedIn &&
      !isAdmin(store.state.users.user) &&
      !isVisualizer(store.state.users.user)
    ) {
      return redirect('/forbidden')
    }
  }
}

function isAllowedSubmodule(user, route) {
  let moduleId = route.params.moduleId
  let submoduleId = route.params.submoduleId
  if (route.query && route.query.moduleId) moduleId = route.query.moduleId
  if (route.query && route.query.submoduleId)
    submoduleId = route.query.submoduleId
  const result = find(user.availableModules, (availableModule) => {
    if (availableModule.submodule && availableModule.submodule !== '') {
      return (
        availableModule.module === moduleId &&
        availableModule.submodule === submoduleId
      )
    } else {
      return availableModule.module === moduleId
    }
  })
  return !!result
}

function isAdmin(user) {
  return (
    user &&
    user.role &&
    includes(['superadmin', 'admin', 'operator', 'editor'], user.role)
  )
}

function isSuperAdmin(user) {
  return user && user.role && user.role === 'superadmin'
}

function isVisualizer(user) {
  return user && user.auxRole && user.auxRole === 'visualizer'
}
