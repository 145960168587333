
import InvalidInformeDialogVue from '@/components/forms/InvalidInformeDialog.vue'

export default {
  // eslint-disable-next-line vue/component-definition-name-casing
  name: 'v-custom-informe',
  components: {
    InvalidInformeDialogVue,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['type', 'value', 'obj'],
  data() {
    return {
      ruleRadio: [(v) => v !== null || this.$t('fieldRequired')],
      userInput: '',
      switchApi: true,
      apiResponse: null,
      invalidApiDialog: false,
      loading: false,
      errorType: null,
      selectedInforme: {},
      inmueble: null,
      zonificacion: null,
    }
  },
  computed: {
    ruleInput() {
      let rules = []
      if (!this.userInput || this.userInput === '') {
        rules = [!!this.userInput || this.$t('required')]
      }
      return rules
    },
    ruleSelect() {
      let rules = []
      if (
        !this.selectedInforme ||
        Object.keys(this.selectedInforme).length === 0
      ) {
        rules = [
          Object.keys(this.selectedInforme).length !== 0 || this.$t('required'),
        ]
      }
      return rules
    },
    input: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  watch: {
    input(val) {
      if (val && val.userInput && this.obj.schema.disabled) {
        this.userInput = val.userInput
        this.apiResponse = val.apiResponse
        this.selectedInforme = val.selectedInforme
        this.inmueble = val.inmueble
        this.zonificacion = val.zonificacion
      }
    },
  },
  mounted() {
    if (this.value && this.value.userInput) {
      this.userInput = this.value.userInput
      this.apiResponse = this.value.apiResponse
      this.selectedInforme = this.value.selectedInforme
      this.inmueble = this.value.inmueble
      this.zonificacion = this.value.zonificacion
    }
  },
  methods: {
    checkForReset() {
      if (this.apiResponse) {
        this.apiResponse = null
        this.errorType = null
        this.selectedInforme = {}
        this.inmueble = null
        this.zonificacion = null
        this.invalidApiDialog = false
        this.$emit('input', null)
      }
    },
    saveAnswer() {
      this.$emit('input', {
        userInput: this.userInput,
        apiResponse: this.apiResponse,
        selectedInforme: this.selectedInforme,
        inmueble: this.inmueble,
        zonificacion: this.zonificacion,
      })
    },
    reset() {
      this.userInput = null
      this.apiResponse = null
      this.errorType = null
      this.selectedInforme = {}
      this.inmueble = null
      this.zonificacion = null
      this.invalidApiDialog = false
      this.$emit('input', null)
    },
    async callApi() {
      this.loading = true
      this.apiResponse = null
      this.errorType = null
      this.selectedInforme = {}
      this.inmueble = null
      this.zonificacion = null
      this.invalidApiDialog = false
      this.$emit('input', null)
      let apiUrl = 'lujan-informeUrbanistico-findUrbanInfoByPadron'
      let paramName = 'padron'
      if (!this.switchApi) {
        apiUrl = 'lujan-informeUrbanistico-findUrbanInfoByNomenclatura'
        paramName = 'nom'
      }
      await this.$axios
        .$post(`/${apiUrl}`, {
          [paramName]: this.userInput,
          paramName,
        })
        .then((result) => {
          this.apiResponse = result
          this.inmueble = this.apiResponse.inmueble
          this.zonificacion = this.apiResponse.zonificacion

          // Mostrar v-dialog de patrón/nomenclatura no encontrado
          if (
            this.inmueble &&
            this.inmueble.message === 'No se encontró el Inmueble'
          ) {
            this.invalidApiDialog = true
            this.errorType = 'padNotFound'
          } else if (
            this.inmueble &&
            this.inmueble.message === 'No se encontró el Inmueble WS'
          ) {
            this.invalidApiDialog = true
            this.errorType = 'nomNotFound'
          }
          // Mostrar v-dialog si el patrón tiene una deuda
          else if (
            this.inmueble &&
            this.inmueble.deudas &&
            this.inmueble.deudas.total &&
            Number(this.inmueble.deudas.total) > 0
          ) {
            this.invalidApiDialog = true
            this.errorType = 'deuda'
          }
          // Mostrar v-dialog si el patrón no tiene informes a utilizar
          else if (
            !this.apiResponse.tramites ||
            (this.apiResponse.tramites && this.apiResponse.tramites <= 0)
          ) {
            this.invalidApiDialog = true
            this.errorType = 'noTramites'
          }

          this.loading = false
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err)
          this.errorType = 'apiError'
          this.invalidApiDialog = true
          this.loading = false
        })
    },
    getInformes() {
      let informes = []
      if (this.apiResponse.tramites && this.apiResponse.tramites.length > 0) {
        informes = this.apiResponse.tramites.map((informe) => {
          return {
            ...informe,
            name: `Número: ${informe.numero} (Fecha: ${
              informe.fecha_inicio
            }) Uso: ${
              informe.zonificacion ? informe.zonificacion.uso_general : ''
            }`,
          }
        })
      }
      return informes
    },
    getName() {
      return this.inmueble.pers_Apellido + ' ' + this.inmueble.pers_Nombre
    },
    getCotitulares() {
      let cotitulares = ''
      if (this.inmueble.cotitulares && this.inmueble.cotitulares.length > 0) {
        this.inmueble.cotitulares.forEach((cotitular, i) => {
          if (i === 0)
            cotitulares =
              cotitulares +
              `${cotitular.pers_Nombre} ${cotitular.pers_Apellido} (${cotitular.descripcion})`
          else
            cotitulares =
              cotitulares +
              `, ${cotitular.pers_Nombre} ${cotitular.pers_Apellido} (${cotitular.descripcion})`
        })
      } else {
        cotitulares = 'No posee'
      }
      return cotitulares
    },
    getTotal() {
      const total = this.inmueble.superficies.find(
        (supe) => supe.tips_Descripcion === 'TOTAL'
      )
      if (total) return total.supe_Superficie + ' m2'
      else return ''
    },
    getSuperficies() {
      let superficies = ''
      if (this.inmueble.superficies && this.inmueble.superficies.length > 0) {
        this.inmueble.superficies.forEach((superficie) => {
          if (superficie.tips_Descripcion === 'TOTAL')
            superficies =
              superficies +
              `TOTAL: ${superficie.supe_Superficie} m2 (${superficie.supe_Plano}), `
          else
            superficies =
              superficies +
              `CUBIERTA: ${superficie.supe_Superficie} m2 (${superficie.supe_Plano}), `
        })
        superficies = superficies.slice(0, -2)
      } else {
        superficies = 'No posee'
      }
      return superficies
    },
    getLoteo() {
      if (this.inmueble && this.inmueble.barr_Descripcion) {
        return this.inmueble.barr_Descripcion
      } else {
        return 'No informa'
      }
    },
    getTipoLoteo() {
      const condicionEspecial = this.inmueble.condicion_especial
      if (condicionEspecial && condicionEspecial[0] && condicionEspecial[0]) {
        return condicionEspecial[0].coes_Descripcion
      } else {
        return 'No informa'
      }
    },
    getAltura() {
      let altura = ''
      if (
        this.zonificacion &&
        this.zonificacion.h_proyecto &&
        this.zonificacion.h_proyecto.desde &&
        this.zonificacion.h_proyecto.hasta
      ) {
        altura = `Desde: ${this.zonificacion.h_proyecto.desde} m - Hasta: ${this.zonificacion.h_proyecto.hasta}`
      } else {
        altura = ''
      }
      return altura
    },
    getDeuda() {
      if (
        this.inmueble &&
        this.inmueble.deudas &&
        this.inmueble.deudas.total &&
        Number(this.inmueble.deudas.total) > 0
      ) {
        return 'Posee deuda'
      } else {
        return 'No posee deuda'
      }
    },
  },
}
