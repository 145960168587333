import { render, staticRenderFns } from "./default.vue?vue&type=template&id=7ad56c7f&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=7ad56c7f&prod&lang=css&"
import style1 from "./default.vue?vue&type=style&index=1&id=7ad56c7f&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SidebarFuncionario: require('/opt/atlassian/pipelines/agent/build/components/sidebar/SidebarFuncionario.vue').default,SidebarCiudadano: require('/opt/atlassian/pipelines/agent/build/components/sidebar/SidebarCiudadano.vue').default})
