
import Vue from 'vue'
import WalletConnect from '@walletconnect/client'
import VueQrcode from 'vue-qrcode'
import { mapGetters } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import firebase from 'firebase/compat/app'

import {
  Certificate,
  setCertificate,
  updateCertificate,
} from '@/services/Certificates'

import { setUserDoc } from '@/services/User'

export default Vue.extend({
  name: 'MiniQrRegister',
  components: { VueQrcode },
  props: {},
  data() {
    return {
      certificate: {} as Certificate,
      refCert: true,
      data: Object(),
      timerQr: true,
      playstore_link: '',
      fullUser: {},
      connector: Object as any,
      address: '',
      functionsURL: process.env.OSCITY_ENDPOINTS_URL,
      cityName: process.env.CITY_NAME,
      timerEnabled: false,
      timerCount: 10,
      certificateTimeout: setTimeout(() => {}, 0),
      projectId: process.env.FB_PROJECT_ID,
    }
  },
  computed: {
    widthGooglePlay(): string {
      switch (this.$vuetify.breakpoint.name) {
        case 'xl':
          return '60%'
        case 'lg':
          return '80%'
        default:
          return '100%'
      }
    },
    ...mapGetters({
      user: 'users/user',
      brand: 'brand/brand',
      certsConfig: 'certsConfig/certsConfig',
    }),
  },
  watch: {
    timerQr(newValue) {
      if (!newValue) {
        this.walletConnectInit()
        this.timerQr = true
      }
    },
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount--
        }, 1000)
      }
    },

    timerCount: {
      handler(value) {
        if (value > 0 && this.timerEnabled) {
          setTimeout(() => {
            this.timerCount--
          }, 1000)
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    await this.walletConnectInit()
    await this.killSession()
  },
  methods: {
    async walletConnectInit() {
      const clientMeta: any = {
        name: `${this.cityName}+${this.projectId}`,
        description: this.brand.issuerDescription,
        url: `${this.functionsURL}/api`,
        icons: this.brand.issuerIcon,
      }
      this.connector = await new WalletConnect({
        bridge: 'https://bridge.walletconnect.org', // Required
      })
      this.connector._clientMeta = await cloneDeep(clientMeta)
      if (!this.connector.connected) {
        await this.connector.createSession()
      }
      await this.subscribeToEvents()
      setTimeout(() => {
        this.timerQr = false
      }, 119000)
    },
    subscribeToEvents() {
      this.connector.on('connect', (error: any, payload: any) => {
        if (error) {
          throw error
        }
        this.onConnect(payload)
        this.timerQr = true
      })
      this.connector.on('disconnect', (error: any) => {
        if (error) {
          throw error
        }
        this.onDisconnect()
      })
    },
    onConnect(payload: any) {
      const { accounts } = payload.params[0]
      this.address = accounts[0]
      this.fullUser = {
        ...this.user,
        public_address: this.address,
      }
      this.certificateWithTimeout(this.address)
      this.timerEnabled = true
    },
    certificateWithTimeout(publicAddress: string): any {
      this.certificateTimeout = setTimeout(async () => {
        await this.certificateChangeUser(publicAddress)
      }, 10000)
    },
    async certificateChangeUser(publicAddress: string): Promise<void> {
      this.data = { ...this.user, public_address: publicAddress }
      setUserDoc(this.$fire, this.data.uid, this.data)
      this.$emit('closeMenu')
      this.$store.commit('users/SET_USER', this.data)
      if (this.refCert) {
        await this.certificateData()
      }
    },
    async certificateData(): Promise<void> {
      this.data.identity = this.certsConfig.identity
      this.data.name = this.data.last_name2
        ? `${this.data.first_name} ${this.data.last_name}  ${this.data.last_name2}`
        : `${this.data.first_name} ${this.data.last_name}`
      this.data.name_citizen = this.data.last_name2
        ? `${this.data.first_name} ${this.data.last_name}  ${this.data.last_name2}`
        : `${this.data.first_name} ${this.data.last_name}`
      this.data.pubkey = this.data.public_address
        ? `ecdsa-koblitz-pubkey:${this.data.public_address}`
        : `ecdsa-koblitz-pubkey:${this.certsConfig.pubkey}`
      this.data.estado_origen = 'En espera'
      this.data.type = 'digital-identity'
      this.data.title = 'Ciudadano Digital'
      this.data.created = firebase.firestore.Timestamp.now()
      this.data.id_user = this.data.uid
      this.data.extra = ''
      this.data.certificateData = ''
      delete this.data.password
      delete this.data.tipoPersona
      delete this.data.autenticated
      delete this.data.activated
      delete this.data.uid
      delete this.data.first_name
      delete this.data.last_name
      if (this.data.last_name2) {
        delete this.data.last_name2
      }
      delete this.data.levelUp
      delete this.data.keyCopyConfirmed
      const idCert = await setCertificate(this.$fire, this.data, 'certificates')
      this.data.id_reference = idCert.id
      await updateCertificate(this.$fire, idCert.id, this.data, 'certificates')
      this.refCert = false
    },
    onDisconnect() {
      this.resetApp()
    },
    resetApp() {
      this.address = ''
      this.walletConnectInit()
    },
    async killSession() {
      if (this.connector.connected) {
        await this.connector.killSession()
        this.resetApp()
        this.disconnectWallet()
        this.timerEnabled = false
        this.timerCount = 10
      }
    },
    disconnectWallet() {
      clearTimeout(this.certificateTimeout)
    },
  },
})
