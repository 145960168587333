import { setDocFirestore } from '@/services/firebaseWrite'

export const getAllModules = async ($fire: any) => {
  return await $fire.firestore.collection('modules').get()
}

export const getLastModule = async ($fire: any) => {
  return await $fire.firestore.collection('modules').limit(1).get()
}

export const getModule = async ($fire: any, id: string) => {
  return await $fire.firestore.collection('modules').doc(id).get()
}

export const setModule = async ($fire: any, data: any) => {
  return await $fire.firestore.collection('modules').add(data)
}

export const updateModule = async ($fire: any, id: string, body: any) => {
  return await setDocFirestore($fire, `modules/${id}`, { ...body }, true)
  // const data = { ...body }
  // return await $fire.firestore
  //   .collection('modules')
  //   .doc(id)
  //   .set(data, { merge: true })
}
