import { getConfiguration } from '@/services/Preventions'

export const state = () => ({
  preventionsConcepts: [],
  preventionsConfiguration: {
    filesSize: 3,
  },
})

export const mutations = {
  SET_PREVENTIONS_CONFIG(state, preventionsConfiguration) {
    state.preventionsConfiguration = Object.assign({}, preventionsConfiguration)
  },
}

export const actions = {
  async getConfiguration({ commit }) {
    try {
      const result = await getConfiguration(this.$fire)
      let preventionsConfiguration = {
        filesSize: 3,
      }
      if (!result.empty) {
        const data = result.docs[0].data()
        preventionsConfiguration = Object.assign(
          {},
          {
            ...data,
            id: data.id,
          }
        )
      }
      commit('SET_PREVENTIONS_CONFIG', preventionsConfiguration)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
      throw new Error(error)
    }
  },
  setConfiguration({ commit }, preventionsConfiguration) {
    commit('SET_PREVENTIONS_CONFIG', preventionsConfiguration)
  },
}

export const getters = {
  getPreventionsConfig(state) {
    return state.preventionsConfiguration
  },
}
