import { setDocFirestore } from '@/services/firebaseWrite'

export const getCertsConfigById = async ($fire: any, id: string) => {
  return await $fire.firestore.collection('certsConfig').doc(id).get()
}

export const getCertsConfig = async ($fire: any) => {
  return await $fire.firestore.collection('certsConfig').limit(1).get()
}

export const setCertsConfig = async ($fire: any, data: any) => {
  return await $fire.firestore.collection('certsConfig').add(data)
}

export const updateCertsConfig = async ($fire: any, id: string, body: any) => {
  // const data = { ...body }
  // return await $fire.firestore.collection('certsConfig').doc(id).set(
  //   {
  //     data,
  //   },
  //   { merge: true }
  // )
  return await setDocFirestore($fire, `certsConfig/${id}`, { ...body }, true)
}
