
export default {
  // eslint-disable-next-line vue/component-definition-name-casing
  name: 'v-custom-api',
  // eslint-disable-next-line vue/require-prop-types
  props: ['type', 'value', 'obj'],
  data() {
    return {
      userInput: '',
    }
  },
  computed: {
    rules() {
      let rules = []
      if (!this.value || !this.value?.input || this.value?.input === '') {
        rules = [
          !!this.value || this.$t('required'),
          !!this.value?.input || this.$t('required'),
        ]
      }
      return rules
    },
    input: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  watch: {
    input(val) {
      if (val && val.input && this.obj.schema.disabled) {
        this.userInput = val.input
      }
    },
  },
  mounted() {
    if (this.value && this.value.input) {
      this.userInput = this.value.input
    }
  },
  methods: {
    removeInput() {
      this.$emit('input', null)
    },
  },
}
